import React from 'react';
import transformProductSize from 'utils/stringUtils/transformProductSize';

const ProductsComponent = ({
  productsForShow,
  secondBundle,
  thirdBundle,
  includedLabel,
  notIncludedLabel,
}) => {
  const item = (tdItem) =>
    tdItem ? (
      <div className="compare-bundles-table-table__product-item">
        <span>{tdItem?.title}</span>
        <span className="product-item__size">
          {transformProductSize({
            quantity: tdItem?.quantity,
            productSize: tdItem?.productSize,
            packagingType: tdItem?.packagingType,
          })}
        </span>
      </div>
    ) : (
      <div className="compare-bundles-table-table__empty">
        <span className="sr-only">{notIncludedLabel}</span>
      </div>
    );

  return productsForShow.map((rowItem, i) => (
    <tr key={i}>
      <th scope="row" className="sr-only">
        {includedLabel}
      </th>
      {rowItem.map((tdItem, index) =>
        index === 0 || (index === 1 && secondBundle) || (index === 2 && thirdBundle) ? (
          <td key={index} className="compare-bundles-table-table__product-row">
            {item(tdItem)}
          </td>
        ) : null
      )}
    </tr>
  ));
};
export default ProductsComponent;
