import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Container } from 'layout';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import ProductCard from 'components/ProductCard';

import { BundleProductsListProps } from './models';
import './BundleProductsList.scss';

const BundleProductsList: FC<BundleProductsListProps> = ({
  text,
  bundleProductsList,
  products,
  usePriceSpider,
  isEanProductId,
}) => {
  const productsList =
    bundleProductsList?.map(({ properties: { quantity, bundleProduct } }) => {
      const productItem = products.nodes.find((item) => item.link === bundleProduct[0].url);

      return { ...productItem, quantity };
    }) || [];

  return productsList.length ? (
    <div
      className="bundle-products-list bundle-products-list--bundle-product"
      data-testid="bundle-products-list"
    >
      <Container>
        {text ? (
          <DangerouslySetInnerHtml className="bundle-products-list__text" html={text} />
        ) : null}
        <div className="bundle-products-list__grid">
          {productsList?.map((product) => (
            <ProductCard
              key={product.title}
              {...product}
              usePriceSpider={usePriceSpider}
              isEanProductId={isEanProductId}
            />
          ))}
        </div>
      </Container>
    </div>
  ) : null;
};

export const query = graphql`
  fragment FragmentBundleProductsList on TBundleProductsList {
    properties {
      showInMenu
      anchorName
      anchorTitle
      text
      bundleProductsList {
        properties {
          quantity
          bundleProduct {
            name
            url
          }
          category {
            properties {
              category
            }
          }
        }
      }
    }
    structure
  }
`;

export default BundleProductsList;
