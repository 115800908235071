import React from 'react';
import GatsbyImage from 'common/GatsbyImage';

const ImageComponent = ({ item }) =>
  item?.images?.[0]?.properties ? (
    <GatsbyImage
      image={item.images?.[0].properties.image}
      objectFit="contain"
      objectPosition="50% 100%"
      className="compare-bundles-table-table__image"
    />
  ) : null;

export default ImageComponent;
