const createProductItem = (bundleItem, compareProducts) => {
  const productItem = compareProducts.nodes.find(
    (el) => el.link === bundleItem?.bundleProduct?.[0]?.url
  );

  return productItem
    ? {
        productSize: productItem?.productSize,
        title: productItem?.title,
        quantity: bundleItem?.quantity,
        packagingType: productItem?.packagingType,
      }
    : null;
};

const createProductsForShow = ({
  compareProducts,
  comparisonFieldsOrder,
  firstBundle,
  secondBundle,
  thirdBundle,
}) => {
  const maxValue =
    comparisonFieldsOrder.map(
      (_, index) =>
        Math.max(
          firstBundle?.list?.[index]?.length,
          secondBundle?.list?.[index]?.length || 0,
          thirdBundle?.list?.[index]?.length || 0
        ) || 0
    ) ?? [];

  const res = comparisonFieldsOrder.map((_, index) => {
    const categoryItems = firstBundle.list[index].concat(
      Array.from(new Array(maxValue[index] - firstBundle.list[index].length))
    );

    return categoryItems.map((itemSubCategory, indexItemSubCategory) => [
      createProductItem(itemSubCategory, compareProducts),
      createProductItem(secondBundle?.list?.[index]?.[indexItemSubCategory], compareProducts),
      createProductItem(thirdBundle?.list?.[index]?.[indexItemSubCategory], compareProducts),
    ]);
  });

  return res?.flat(1);
};

export default createProductsForShow;
