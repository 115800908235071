import React from 'react';
import classNames from 'classnames';
import Icon from 'common/IconCustom';
import { DangerouslySetInnerHtml } from 'layout';

const BenefitsComponent = ({
  benefitsToShow,
  secondBundle,
  thirdBundle,
  benefitsLabel,
  notIncludedLabel,
}) => {
  const component = (rowItem) =>
    rowItem?.properties?.description ? (
      <DangerouslySetInnerHtml
        html={rowItem.properties.description}
        className="compare-bundles-table-table__benefits-description"
      />
    ) : null;

  const itemRow = (rowItem) =>
    rowItem ? (
      <div className="compare-bundles-table-table__benefits-item">
        <Icon icon={rowItem?.properties?.icon?.[0]} />
        {component(rowItem)}
      </div>
    ) : (
      <div className="compare-bundles-table-table__empty">
        <span className="sr-only">{notIncludedLabel}</span>
      </div>
    );

  return benefitsToShow.map((item, i) => (
    <tr key={i}>
      <th scope="row" className="sr-only">
        {benefitsLabel}
      </th>
      {item.map((rowItem, index) =>
        index === 0 || (index === 1 && secondBundle) || (index === 2 && thirdBundle) ? (
          <td
            key={index}
            className={classNames('compare-bundles-table-table__benefit', {
              'compare-bundles-table-table__benefit--empty': !rowItem,
            })}
          >
            {itemRow(rowItem)}
          </td>
        ) : null
      )}
    </tr>
  ));
};
export default BenefitsComponent;
