const convertPriceString = ({ price, oldPrice, placeHolder }) => {
  const priceNumber = Number(price) || 0;
  const oldPriceNumber = Number(oldPrice) || 0;

  const discount =
    oldPriceNumber > priceNumber ? Number((oldPriceNumber - priceNumber).toFixed(2)) : 0;

  const discountPercent =
    discount > 0 ? Number((discount / oldPriceNumber).toFixed(2)) * 100 : null;

  return discountPercent ? `${placeHolder?.replace(/%percentage%/i, discountPercent)}` : '';
};

export default convertPriceString;
