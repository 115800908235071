const createBundleData = ({ findLink, bundles, comparisonFieldsOrder }) => {
  if (!findLink) return null;

  const currentBundle = bundles.find((el) => el.link === findLink);
  if (!currentBundle) return null;

  const includedProductsList = currentBundle?.body
    ?.find((el) => el.structure === 'Bundle Products List')
    ?.properties?.bundleProductsList.map((el) => el.properties);

  const list = comparisonFieldsOrder.map((el) => {
    const field = el?.properties?.category?.[0];

    return includedProductsList?.filter(
      (item) => item.category?.[0]?.properties?.category?.[0] === field
    );
  });

  const bundleBenefits =
    currentBundle?.body?.find((el) => el.structure === 'Benefits')?.properties?.items || null;

  return {
    ...currentBundle,
    list,
    bundleBenefits,
  };
};

export default createBundleData;
