import React, { FC, useEffect } from 'react';
import { graphql } from 'gatsby';

import BodyRenderer from 'common/BodyRenderer';
import { Layout } from 'layout';
import AnchorMenu from 'components/AnchorMenu';
import ProductDetails from 'components/ProductDetails';
import ProductBenefits from 'components/ProductBenefits';
import WideBanner from 'components/WideBanner';
import BundleProductsList from 'components/BundleProductsList';
import SlimBanner from 'components/SlimBanner';
import SignUp from 'components/SignUp';
import CompareBundlesTable from 'components/CompareBundlesTable';
import FaqSection from 'gatsby-theme-husky/src/components/FaqSection';
import { BundlePageTypes } from '@shared/types/umbraco/content/bundlePage';
import ProductPageSchemaWrapper from 'common/ProductPageSchemaWrapper';
import { gtmService } from 'utils/gtmService';

const bodyBlocks = {
  Benefits: ({ properties }, keyId) => <ProductBenefits key={keyId} {...properties} />,
  'Info Banner Wide': ({ properties }, keyId) => <WideBanner key={keyId} {...properties} />,
  'Signup Placeholder': ({ properties }, keyId) => <SignUp key={keyId} {...properties} />,
  'Bundle Products List': ({ properties }, keyId) => (
    <BundleProductsList key={keyId} {...properties} />
  ),
  'Slim banner': ({ properties }, keyId) => <SlimBanner key={keyId} {...properties} />,
  'Compare Bundles Table': ({ properties }, keyId) => (
    <CompareBundlesTable key={keyId} {...properties} />
  ),
  'FAQ Section': ({ properties }, keyId) => <FaqSection key={keyId} {...properties} />,
  'Anchor Menu Placeholder': ({ properties }, keyId) => <AnchorMenu key={keyId} {...properties} />,
};

const BundlePage: FC<BundlePageTypes.IProperties> = ({
  pageContext: { breadCrumbs, link: bundleLink, searchUrl, pageName },
  data: {
    page: {
      nodes: [
        {
          skuId,
          ean,
          shopifyId,
          title,
          shortDescription,
          bgImage,
          productInfo,
          defaultCompositions: {
            footer,
            header,
            productSettings,
            waiSettings,
            siteSettings,
            brandSettings,
            singupFormBaner,
            comparisonTableSettings,
            purchaseSettings,
            warning,
            signUpPopup,
            signUpFormPopup,
            promoPlatformForm,
          },
          seoMetaKeywords,
          seoMetaTitle,
          seoMetaTitleTemplate,
          seoMetaDescription,
          seoExternalHreflangs,
          seoNoIndex,
          seoCanonicalUrl,
          body,
          anchorMenu,
          tags,
          images,
          availableLinks,
          alternateUrls,
          ogImageUrl,
        },
      ],
    },
    products,
    bundles,
    compareProducts,
  },
}) => {
  const { isShopify, lang } = siteSettings || {};
  const imageUrl = ogImageUrl || images?.[0]?.properties?.image?.fallbackUrl;
  const imageAlt = images?.[0]?.properties?.imageAlt;
  useEffect(() => {
    gtmService.emitProductListingView(pageName, products?.nodes);
  }, []);

  return (
    <Layout
      {...{
        footer,
        header,
        seoMetaKeywords,
        seoMetaTitle,
        seoMetaTitleTemplate,
        seoNoIndex,
        seoMetaDescription,
        seoExternalHreflangs,
        seoCanonicalUrl,
        siteSettings,
        brandSettings,
        purchaseSettings,
        skipText: waiSettings?.skipText,
        warning,
        link: bundleLink,
        searchUrl,
        alternateUrls,
        imageUrl,
        imageAlt,
        signUpPopup,
        singupFormBaner,
        signUpFormPopup,
        promoPlatformForm,
      }}
    >
      <ProductPageSchemaWrapper
        brandSettings={brandSettings}
        title={title}
        productSize=""
        productScent=""
        packagingType=""
        images={images}
        shortDescription={shortDescription}
        link={bundleLink}
        skuId={skuId}
        shopifyId={shopifyId}
        isShopify={isShopify}
        lang={lang}
      />
      <ProductDetails
        {...{ ...productSettings, ...siteSettings }}
        cartPath={header?.cartPath}
        skuId={skuId}
        ean={ean}
        shopifyId={shopifyId}
        title={title}
        images={images}
        shortDescription={shortDescription}
        breadCrumbs={breadCrumbs}
        tags={tags}
        bgImage={bgImage}
        productsList={productInfo}
        waiSettings={waiSettings}
        cartProductsData={bundles}
        availableLinks={availableLinks}
      />

      <BodyRenderer
        bodyData={body}
        bodyStructure={bodyBlocks}
        bodyItemProps={{
          ...{
            cartPath: header.cartPath,
            cartProductsData: bundles,
            tags,
            products,
            singupFormBaner,
            comparisonTableSettings,
            bundles,
            link: bundleLink,
            compareProducts,
            isShopify: siteSettings?.isShopify,
            lang: siteSettings?.lang,
            anchorMenu,
            usePriceSpider: siteSettings?.usePriceSpider,
            isEanProductId: siteSettings?.isEanProductId,
          },
        }}
      />
    </Layout>
  );
};
export default BundlePage;

export const query = graphql`
  query BundleQuery(
    $relatedProductsLinks: [String]
    $link: String
    $compareBundlesProductsLinks: [String]
  ) {
    page: allBundle(filter: { link: { eq: $link } }) {
      nodes {
        useRedirects
        productInfo
        seoMetaTitle
        seoMetaTitleTemplate
        seoMetaDescription
        seoMetaKeywords
        seoNoIndex
        seoExternalHreflangs {
          key
          value
        }
        seoCanonicalUrl {
          url
        }
        ean
        alternateUrls {
          lang
          url
          path
        }
        ogImageUrl
        anchorMenu {
          linkToSection
          name
          hasBg
        }
        bgImage {
          ...FragmentGatsbyProps
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }

        availableLinks {
          properties {
            image {
              logo {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 150) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              imageAlt
              ariaLabel
            }

            link {
              queryString
              url
              target
            }
          }
        }
        images {
          properties {
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            imageAlt
          }
        }
        title
        shortDescription
        tags {
          color {
            label
          }
          isFamily
        }
        shopifyId
        skuId
        body {
          ... on TInfoBannerWide {
            ...FragmentWideBanner
          }
          ... on TBenefits {
            ...FragmentBenefits
          }
          ... on TBundleProductsList {
            ...FragmentBundleProductsList
          }
          ... on TSlimbanner {
            ...FragmentSlimBanner
          }
          ... on TTextSection {
            ...FragmentTextSection
          }
          ... on TSignupPlaceholder {
            ...FragmentSignUp
          }
          ... on TCompareBundlesTable {
            ...FragmentCompareBundlesTable
          }
          ... on TFAQSection {
            ...FragmentFaqSection
          }
          ... on TAnchorMenuPlaceholder {
            ...FragmentAnchorMenu
          }
        }
        defaultCompositions {
          purchaseSettings {
            ...FragmentPurchaseSettings
          }
          footer {
            ...FragmentFooter
          }
          header {
            ...FragmentHeader
          }
          waiSettings {
            skipText
            ariaPrev
            ariaNext
          }
          siteSettings {
            ...FragmentSiteSettingsComposition
          }
          brandSettings {
            ...FragmentBrandSettingsComposition
          }
          signUpPopup {
            ...FragmentSignUpPopup
          }
          singupFormBaner {
            ...FragmentSignUpBanner
          }
          signUpFormPopup {
            ...FragmentSignUpFormPopup
          }
          promoPlatformForm {
            ...FragmentPromoPlatformForm
          }
          comparisonTableSettings {
            currentLabel
            button
            ariaButton
            includedLabel
            benefitsLabel
            notIncludedLabel
            priceLabel
            linksLabel
            imagesLabel
            discountPlaceHolder
            comparisonFieldsOrder {
              properties {
                category
              }
            }
          }
          productSettings {
            availableLabel
          }
        }
      }
    }
    products: allProduct(filter: { link: { in: $relatedProductsLinks } }) {
      nodes {
        link
        ean
        useRedirects
        productSize
        packagingType
        productScent
        tags {
          isFamily
          isLabel
          title
          color {
            label
          }
        }
        title
        shortDescription
        lang
        images {
          properties {
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageAlt
          }
        }
        cardBenefits
      }
    }
    bundles: allBundle {
      nodes {
        useRedirects
        productInfo
        images {
          properties {
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageAlt
          }
        }
        title
        bundleProducts {
          link
          quantity
        }
        shopifyId
        skuId
        link
        body {
          # ... on TBenefits {
          #   properties {
          #     items {
          #       properties {
          #         description
          #         icon
          #       }
          #     }
          #   }
          #   structure
          # }
          ... on TBundleProductsList {
            properties {
              bundleProductsList {
                properties {
                  quantity
                  bundleProduct {
                    name
                    url
                  }
                  category {
                    properties {
                      category
                    }
                  }
                }
              }
            }
            structure
          }
        }
      }
    }
    compareProducts: allProduct(filter: { link: { in: $compareBundlesProductsLinks } }) {
      nodes {
        link
        productSize
        packagingType
        productScent
        title
        shortDescription
      }
    }
  }
`;
