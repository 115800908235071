import React, { FC, useEffect, useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import { Container, DangerouslySetInnerHtml } from 'layout';
import useScreenRecognition from 'hooks/useScreenRecognition';
import Button from 'common/Button';
import CustomDropdown from 'components/CustomDropdown';
import './CompareBundlesTable.scss';
import AddToCartButton from 'components/AddToCartButton';
import useShopifyHelper from 'hooks/useShopifyHelper';
import classnames from 'classnames';
import ProductsComponent from './components/ProductsComponent';
import BenefitsComponent from './components/BenefitsComponent';
import createBenefitsToShow from './utils/createBenefitsToShow';
import createProductsForShow from './utils/createProductsForShow';
import createBundleData from './utils/createBundleData';
import ImageComponent from './components/ImageComponent';
import convertPriceString from './utils/convertPriceString';
import priceString from './utils/priceString';
import { CompareBundlesTableProps } from './models';

const CompareBundlesTable: FC<CompareBundlesTableProps> = ({
  title,
  bundlesForComparison,
  comparisonTableSettings,
  bundles,
  link,
  compareProducts,
  lang,
  isShopify,
  cartProductsData,
}) => {
  const { isDesktop } = useScreenRecognition();
  const [secondCompareItem, setSecondCompareItem] = useState(null);
  const [thirdCompareItem, setThirdCompareItem] = useState(null);
  const {
    currentLabel = '',
    button = '',
    ariaButton = '',
    includedLabel = '',
    benefitsLabel = '',
    notIncludedLabel = '',
    priceLabel = '',
    comparisonFieldsOrder = [],
    discountPlaceHolder = '',
    linksLabel = '',
    imagesLabel = '',
  } = comparisonTableSettings;

  const { mergeCmsShopifyProducts } = useShopifyHelper({ isShopify, lang });
  const bundlesWithPriceList = useMemo(
    () => (isShopify ? mergeCmsShopifyProducts(bundles) : bundles?.nodes),
    [bundles?.nodes?.length, mergeCmsShopifyProducts]
  );

  const dropDownList = bundles?.nodes
    ?.map((el) => ({ title: el.title, value: el.link }))
    ?.filter((el) => el.value !== link);

  useEffect(() => {
    setSecondCompareItem(bundlesForComparison?.[0]?.url || dropDownList?.[0]?.value || null);
    setThirdCompareItem(bundlesForComparison?.[1]?.url || dropDownList?.[1]?.value || null);
  }, []);

  const firstBundle = useMemo(
    () =>
      createBundleData({ findLink: link, bundles: bundlesWithPriceList, comparisonFieldsOrder }),
    [link, JSON.stringify(bundlesWithPriceList)]
  );
  const secondBundle = useMemo(
    () =>
      createBundleData({
        findLink: secondCompareItem,
        bundles: bundlesWithPriceList,
        comparisonFieldsOrder,
      }),
    [secondCompareItem, JSON.stringify(bundlesWithPriceList)]
  );
  const thirdBundle = useMemo(
    () =>
      isDesktop || !secondBundle
        ? createBundleData({
            findLink: thirdCompareItem,
            bundles: bundlesWithPriceList,
            comparisonFieldsOrder,
          })
        : null,
    [thirdCompareItem, isDesktop, JSON.stringify(bundlesWithPriceList)]
  );

  const productsForShow = useMemo(
    () =>
      createProductsForShow({
        compareProducts,
        comparisonFieldsOrder,
        firstBundle,
        secondBundle,
        thirdBundle,
      }),
    [firstBundle, secondCompareItem, thirdCompareItem]
  );
  const awaitingRelease = true;
  const benefitsToShow = useMemo(
    () => (awaitingRelease ? [] : createBenefitsToShow({ firstBundle, secondBundle, thirdBundle })),
    [firstBundle, secondBundle, thirdBundle]
  );

  const handleChangeSecondSelect = (value) => {
    setSecondCompareItem(value);
  };

  const handleChangeThirdSelect = (value) => {
    setThirdCompareItem(value);
  };

  const currentLabelRow = () => (
    <>
      <th scope="row" className="sr-only">
        {linksLabel}
      </th>
      <td className="compare-bundles-table-table__current--title">{currentLabel}</td>
      {secondBundle ? (
        <td className="compare-bundles-table-table__current--title">
          <Button
            to={secondCompareItem}
            ariaLabel={`${secondBundle.title} ${ariaButton}`}
            variant="secondary-link"
            iconSuffix="chevron-right"
          >
            {button}
          </Button>
        </td>
      ) : null}
      {thirdBundle ? (
        <td className="compare-bundles-table-table__current--title">
          <Button
            to={thirdCompareItem}
            ariaLabel={`${thirdBundle.title} ${ariaButton}`}
            variant="secondary-link"
            iconSuffix="chevron-right"
          >
            {button}
          </Button>
        </td>
      ) : null}
    </>
  );

  const isShowPriceRow = useMemo(
    () => firstBundle?.price || secondBundle?.price || thirdBundle?.price,
    [firstBundle?.price, secondBundle?.price, thirdBundle?.price]
  );

  const compareBundlesArr = useMemo(
    () => [firstBundle, secondBundle, thirdBundle].filter(Boolean),
    [firstBundle, secondBundle, thirdBundle]
  );

  const priceRow = () => (
    <>
      <th scope="row" className="sr-only">
        {priceLabel}
      </th>
      {compareBundlesArr.map((bundle, index) =>
        bundle ? (
          <td key={`${bundle.skuId}${index}`} className="compare-bundles-table-table__price">
            {bundle.price && (
              <>
                {priceString(bundle.currencieLabel, bundle.price)}
                <span>
                  {convertPriceString({
                    price: bundle.price,
                    oldPrice: bundle.oldPrice,
                    placeHolder: discountPlaceHolder,
                  })}
                </span>
              </>
            )}
          </td>
        ) : null
      )}
    </>
  );

  return (
    <div className="compare-bundles-table">
      <Container fluid>
        <DangerouslySetInnerHtml className="compare-bundles-table__title" html={title} />

        <table
          className={classnames('compare-bundles-table-table', {
            'compare-bundles-table-table--second': compareBundlesArr.length === 2,
            'compare-bundles-table-table--alone': compareBundlesArr.length === 1,
          })}
        >
          <thead>
            <tr>
              <td className="sr-only" />
              <th scope="col" className="compare-bundles-table-table__title">
                {firstBundle?.title}
              </th>
              {secondBundle ? (
                <th scope="col" className="compare-bundles-table-table__title">
                  <CustomDropdown
                    dropDownList={dropDownList}
                    value={secondCompareItem}
                    onChange={handleChangeSecondSelect}
                  />
                </th>
              ) : null}
              {thirdBundle ? (
                <th scope="col" className="compare-bundles-table-table__title">
                  <CustomDropdown
                    dropDownList={dropDownList}
                    value={thirdCompareItem}
                    onChange={handleChangeThirdSelect}
                  />
                </th>
              ) : null}
            </tr>
          </thead>
          <tfoot>
            <tr aria-hidden="true">
              <td className="sr-only" />
              {compareBundlesArr.map((bundle, index) =>
                bundle ? (
                  <td
                    key={`${bundle.skuId}${index}`}
                    className="compare-bundles-table-table__subtitle"
                  >
                    {priceLabel}
                  </td>
                ) : null
              )}
            </tr>
            {isShowPriceRow ? <tr>{priceRow()}</tr> : null}
            <tr className="compare-bundles-table-table__view-bundle">{currentLabelRow()}</tr>
            <tr className="compare-bundles-table-table__last-row">
              <td className="sr-only" />
              {compareBundlesArr.map((bundle, index) =>
                bundle && bundle.skuId && bundle.shopifyId ? (
                  <td
                    key={`${bundle.skuId}${index}`}
                    className="compare-bundles-table-table__add-to-cart"
                  >
                    <AddToCartButton
                      isShopify={isShopify}
                      lang={lang}
                      cartProductsData={cartProductsData}
                      productskuId={bundle.skuId}
                      productShopifyId={bundle.shopifyId}
                      buttonVariant="secondary"
                      productTitle={bundle.title}
                    />
                  </td>
                ) : (
                  <td key={`empty${index}`}>
                    <span className="sr-only">{notIncludedLabel}</span>
                  </td>
                )
              )}
            </tr>
          </tfoot>
          <tbody>
            <tr>
              <th scope="row" className="sr-only">
                {imagesLabel}
              </th>
              {compareBundlesArr.map((bundle, index) =>
                bundle ? (
                  <td key={`${bundle.skuId}${index}`}>
                    <ImageComponent item={bundle} />
                  </td>
                ) : null
              )}
            </tr>
            {isShowPriceRow ? <tr>{priceRow()}</tr> : null}
            <tr>{currentLabelRow()}</tr>
            <tr aria-hidden="true">
              <td className="sr-only" />
              {compareBundlesArr.map((bundle, index) =>
                bundle ? (
                  <td
                    key={`${bundle.skuId}${index}`}
                    className="compare-bundles-table-table__subtitle"
                  >
                    {includedLabel}
                  </td>
                ) : null
              )}
            </tr>

            {productsForShow?.length ? (
              <ProductsComponent
                productsForShow={productsForShow}
                secondBundle={secondBundle}
                thirdBundle={thirdBundle}
                notIncludedLabel={notIncludedLabel}
                includedLabel={includedLabel}
              />
            ) : null}

            {benefitsToShow?.length ? (
              <tr aria-hidden="true">
                <td className="sr-only" />
                {compareBundlesArr.map((bundle, index) =>
                  bundle ? (
                    <td
                      key={`${bundle.skuId}${index}`}
                      className="compare-bundles-table-table__subtitle"
                    >
                      {benefitsLabel}
                    </td>
                  ) : null
                )}
              </tr>
            ) : null}

            {benefitsToShow?.length ? (
              <BenefitsComponent
                benefitsToShow={benefitsToShow}
                secondBundle={secondBundle}
                thirdBundle={thirdBundle}
                notIncludedLabel={notIncludedLabel}
                benefitsLabel={benefitsLabel}
              />
            ) : null}
          </tbody>
        </table>
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentCompareBundlesTable on TCompareBundlesTable {
    properties {
      title
      showInMenu
      anchorName
      anchorTitle
      bundlesForComparison {
        name
        url
      }
    }
    structure
  }
`;

export default CompareBundlesTable;
